<template lang="pug">
.top-header-second-line.is-flex
  .left-side.is-flex.is-hidden-tablet-only
    top-header-logo.is-hidden-tablet
  .center-side.is-flex.is-hidden-mobile
    top-header-logo
  .right-side.has-text-right.is-flex-touch
    .search-icon.search-mobile(@click="openSearchModal")
      icon(icon-name="search", width='20', height='20', icon-color="#2e2e2e", key="ec03e3b5-47ee-4aa2-a117-9da3113c64b5")
    template(v-for="(item, index) in headerItems")
      tooltip.right-side-item.is-inline-flex(:key="index+'-'+keyTooltip", :disabled="isMobile", :class="[item.class, { 'show': getItemCount(item) > 0 && item.icon !== 'compare'}]")
        .tooltip-anchor(slot="target")
          div(@click="handleItemClick(item)")
            template(v-if="!item.isAccount")
              v-link(:to="item.link", :class="{'show': getItemCount(item) > 0}")
                .badge(v-if="getItemCount(item) > 0") {{ getItemCount(item) }}
                icon.animate-pulse(:iconName="item.icon", icon-color="#2e2e2e")
            template(v-else)
              .account-icon-wrapper.is-flex
                icon.animate-pulse.is-hidden-mobile(:iconName="item.icon", icon-color="#2e2e2e")
                i.fa-user.is-hidden-tablet(:class="loggedIn ? 'fas' : 'far'")
        .tooltip-body
          span.is-hidden-mobile.right-side-item-label {{ getItemLabel(item) }}
    .right-side-item.is-hidden-tablet-only.is-hidden-desktop
      hamburger-component(:state="menuOpen", @click.native="toggleMenu")
    .menu-wrapper.is-hidden-mobile
      collapse-transition
        user-menu(
          v-if="showUserMenu",
          @close="onCloseHandler",
          v-on-clickaway="onCloseHandler"
        )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { CollapseTransition } from 'vue2-transitions'
import { mixin as clickaway } from 'vue-clickaway'
import debounce from 'lodash/debounce'
import { companyName } from '~~/utils/definitions/defaults'
import deviceDetect from '~~/mixins/deviceDetect'

export default {
  name: 'TopHeaderSecondLineComponent',

  components: {
    CollapseTransition,
    QuickSearch: () =>
      import('~~/components/Layout/TopHeader/QuickSearch/QuickSearch'),
    HamburgerComponent: () => import('../MainMenu/MainMenuMobile/Hamburger'),
    TopHeaderLogo: () => import('./TopHeaderLogo'),
    UserMenu: () => import('./TopHeaderSecondLine/UserMenu/UserMenu.vue'),
    Tooltip: () => import('~~/components/Common/Tooltip')
  },

  mixins: [clickaway, deviceDetect],

  data() {
    return {
      showUserMenu: false,
      inputFocusedUser: false,
      tooltipKey: null
    }
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.loggedIn,
      profile: (state) => state.auth.user || {}
    }),

    ...mapGetters({
      menuOpen: 'app/menuOpen',
      cartItems: 'cart/items',
      wishlistItems: 'favorites/allListItems',
      compareListItems: 'compareList/allListItems'
    }),

    companyName() {
      return companyName[this.$site.name]
    },

    keyTooltip() {
      // This will update whenever loggedIn changes
      return `${this.loggedIn ? 'logged-in' : 'logged-out'}-${this.tooltipKey}`
    },

    headerItems() {
      return [
        {
          icon: 'favorites',
          link: { name: 'favorites' },
          label: 'layout.header.secondLine.favorites',
          class: 'wishlist-icon',
          countGetter: 'wishlistItems'
        },
        {
          icon: 'compare',
          link: { name: 'compare-list' },
          label: 'layout.header.secondLine.compare',
          class: 'compare-icon is-hidden-mobile',
          countGetter: 'compareListItems'
        },
        {
          icon: 'cart',
          link: { name: 'cart-index' },
          label: 'layout.header.secondLine.cart',
          class: 'cart-icon',
          countGetter: 'cartItems'
        },
        {
          icon: 'account',
          label: 'layout.header.secondLine.account',
          class: 'account-icon is-hidden-mobile',
          isAccount: true
        }
      ]
    }
  },

  watch: {
    '$route.path'() {
      if (this.menuOpen) this.setMenuState(false)
      if (this.showUserMenu) this.showUserMenu = false
    }
  },

  mounted() {
    this.$root.$on('login-complete', this.onOpenHandler)
  },

  beforeDestroy() {
    this.$root.$off('login-complete', this.onOpenHandler)
  },

  methods: {
    ...mapActions({
      setMenuState: 'app/setMenuState',
      toggleMenu: 'app/toggleMenu'
    }),

    openSearchModal() {
      this.$root.$emit('search-modal')
    },

    handlerFocus() {
      this.inputFocusedUser = true
    },

    handlerBlur() {
      this.inputFocusedUser = false
    },

    onCloseHandler() {
      this.showUserMenu = false
    },

    onOpenHandler() {
      this.showUserMenu = true
    },

    onAccountClick() {
      if (!this.$store.state.auth.loggedIn) {
        this.$root.$emit('auth-modal')
        return
      }
      this.showUserMenu = !this.showUserMenu
      this.$nextTick(() => {
        if (!this.$device.isMobile) return // do not emit event on non-mobile devices b/c this will break desktop/tablet layout (caching things)
        if (!this.loggedIn) this.$root.$emit('account-menu')
      })
    },

    getItemCount(item) {
      return this[item.countGetter]?.length || 0
    },

    getItemLabel(item) {
      if (item.isAccount) {
        return this.loggedIn && this.profile && this.profile.firstName
          ? this.$t('account.hi', { name: this.profile.firstName })
          : this.$t(item.label)
      }
      return this.$t(item.label)
    },

    handleItemClick(item) {
      if (item.isAccount) {
        this.onAccountClick()
      }
    },

    updateTooltipKey() {
      this.tooltipKey = this.windowWidth
    },

    recalculateWidth: debounce(function () {
      this.windowWidth = window.innerWidth
      this.updateTooltipKey()
    }, 10)
  }
}
</script>

<style lang="sass" scoped src="./TopHeaderSecondLine.sass"></style>
